import { toast } from "react-toastify";
import API from "./ActionTypes";
import {
  BASEURL,
  DELETE_COURSE,
  DELETE_COURSE_FAILURE,
  DELETE_COURSE_SUCCESS,
  GET_COURCES,
  GET_COURCES_FAILURE,
  GET_COURCES_SUCCESS,
  GET_SUB_COURSES,
  GET_SUB_COURSES_FAILURE,
  GET_SUB_COURSES_SUCCESS,
} from "./ActionTypes";

export const getCources =
  ({ page, limit }) =>
  async (dispatch) => {
    dispatch({
      type: GET_COURCES,
    });
    try {
      const res = await API.get(
        `${BASEURL}api/course/page?limit=${limit}&page=${page}`
      );
      if (res.status === 200) {
        dispatch({
          type: GET_COURCES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_COURCES_FAILURE,
      });
      toast.error("Something went wrong !");
    }
  };

export const getSubCources = (id) => async (dispatch) => {
  dispatch({
    type: GET_SUB_COURSES,
  });
  try {
    const res = await API.get(`${BASEURL}api/subcourse/course/${id}`);
    if (res.status === 200) {
      dispatch({
        type: GET_SUB_COURSES_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SUB_COURSES_FAILURE,
    });
    toast.error("Something went wrong !");
  }
};

export const getCourcesByInstructor =
  ({ page, limit, id }) =>
  async (dispatch) => {
    dispatch({
      type: GET_COURCES,
    });
    try {
      const res = await API.get(
        `${BASEURL}api/course/instructor/${id}?limit=${limit}&page=${page}`
      );
      if (res.status === 200) {
        dispatch({
          type: GET_COURCES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_COURCES_FAILURE,
      });
      toast.error("Something went wrong !");
    }
  };

export const getCourcesByCategory =
  ({ page, limit, category_id }) =>
  async (dispatch) => {
    dispatch({
      type: GET_COURCES,
    });
    try {
      const res = await API.get(
        `${BASEURL}api/course/category/${category_id}/?limit=${limit}&page=${page}`
      );
      if (res.status === 200) {
        dispatch({
          type: GET_COURCES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_COURCES_FAILURE,
      });
      toast.error("Something went wrong !");
    }
  };

export const deleteCource = (id) => async (dispatch) => {
  dispatch({
    type: DELETE_COURSE,
  });
  try {
    const res = await API.get(`${BASEURL}api/course/instructor/${id}`);
    if (res.status === 200 || res.status === 201) {
      dispatch({
        type: DELETE_COURSE_SUCCESS,
        payload: id,
      });
      toast.success("Course delete successfully");
    }
  } catch (e) {
    dispatch({
      type: DELETE_COURSE_FAILURE,
    });
    toast.error("Something went wrong");
  }
};
