import {
  DELETE_SESSIONS,
  DELETE_SESSIONS_FAILURE,
  DELETE_SESSIONS_SUCCESS,
  GET_SESSIONS,
  GET_SESSIONS_FAILURE,
  GET_SESSIONS_SUCCESS,
} from "../Actions/ActionTypes";

let initialState = {
  loading: false,
  sessions: [],
};

const SessionReducer = (state = initialState, actions) => {
  switch (actions.type) {
    // GET_SESSIONS
    case GET_SESSIONS:
      return { ...state, loading: true };

    case GET_SESSIONS_SUCCESS:
      return { ...state, sessions: actions.payload, loading: false };

    case GET_SESSIONS_FAILURE:
      return { ...state, loading: false };

    // DELETE_SESSIONS
    case DELETE_SESSIONS:
      return { ...state, loading: true };

    case DELETE_SESSIONS_SUCCESS:
      return { ...state, loading: false };

    case DELETE_SESSIONS_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default SessionReducer;
