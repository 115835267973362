import React, { useEffect } from "react";
import Heading from "../Components/Heading";
import HOC from "../Layouts/HOC";

import {
  getCources,
  getCourcesByCategory,
} from "../Redux/Actions/CourceAction";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { IoIosArrowBack } from "react-icons/io";
import ButtonLoader from "../Components/ButtonLoader";

const pageSize = 100;

const Courses = () => {
  const category_id = new URLSearchParams(document.location.search).get(
    "category_id"
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.CourseReducer.loading);
  const course = useSelector((state) => state.CourseReducer.courses);

  //  table data
  const columns = [
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Name</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Price</div>
      ),
      selector: (row) => row.price,
      sortable: true,
    },
    // {
    //   name: (
    //     <div className="uppercase tracking-wider font-bold text-sm">Image</div>
    //   ),
    //   selector: (row) => row.image,
    // },

    // {
    //   name: (
    //     <div className="uppercase tracking-wider font-bold text-sm">
    //       Description
    //     </div>
    //   ),
    //   selector: (row) => row.description,
    //   sortable: true,
    // },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          Actions
        </div>
      ),
      selector: (row) => row.actions,
    },
  ];

  const data = course?.data?.map((item, index) => {
    return {
      id: item._id,
      title: <div className="capitalize">{item.name}</div>,
      price: <div className="capitalize">Rs. {item.price}</div>,

      image: (
        <img
          src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
          alt={item.name}
          className="w-32 my-3 object-cover object-center"
        />
      ),
      description: item.description,
      actions: (
        <div className="font-bold uppercase flex gap-3 text-xl">
          <Link
            to={`/sub_courses?course_id=${item._id}`}
            state={{ item: item._id }}
            className="text-xs underline cursor-pointer font-semibold tracking-wider text-color"
          >
            Sub-Courses
          </Link>
          <Link
            to={`/enrollments?course_id=${item._id}`}
            state={{ item: item._id }}
            className="text-xs underline cursor-pointer font-semibold tracking-wider text-color"
          >
            Enrollments
          </Link>
        </div>
      ),
    };
  });

  useEffect(() => {
    if (category_id) {
      dispatch(getCourcesByCategory({ page: 1, limit: pageSize, category_id }));
    } else {
      dispatch(getCources({ page: 1, limit: pageSize }));
    }
  }, [dispatch, category_id]);

  return (
    <>
      <section>
        <div className="flex border-b py-2 mb-4 items-center justify-between">
          <Heading title={`Courses ${category_id ? "By Category" : ""} `} />
          {category_id && (
            <div
              className=" md:text-base text-xs sm:text-sm text-white cursor-pointer  w-8 h-8 flex justify-center items-center shadow rounded-full tracking-widest bg-indigo-500 hover:bg-indigo-600 "
              onClick={() => navigate(-1)}
            >
              <IoIosArrowBack />
            </div>
          )}
        </div>
        {loading ? (
          <ButtonLoader />
        ) : (
          <DataTable columns={columns} data={data} pagination sorting />
        )}
      </section>
    </>
  );
};

export default HOC(Courses);
