import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_SUCCESS,
  REMOVE_CATEGORIES,
  REMOVE_CATEGORIES_SUCCESS,
  REMOVE_CATEGORIES_FAILURE,
  ADD_CATEGORIES_REQUEST,
  ADD_CATEGORIES_REQUEST_SUCCESS,
  ADD_CATEGORIES_REQUEST_FAILURE,
  UPDATE_CATEGORIES_REQUEST,
  UPDATE_CATEGORIES_REQUEST_SUCCESS,
  UPDATE_CATEGORIES_REQUEST_FAILURE,
} from "../Actions/ActionTypes";

let initialState = {
  loading: false,
  categories: [],
};

const CategoryReducer = (state = initialState, actions) => {
  switch (actions.type) {
    // UPDATE_CATEGORIES_REQUEST
    case UPDATE_CATEGORIES_REQUEST:
      return { ...state, loading: true };

    case UPDATE_CATEGORIES_REQUEST_SUCCESS:
      return { ...state, loading: false };

    case UPDATE_CATEGORIES_REQUEST_FAILURE:
      return { ...state, loading: false };

    // ADD_CATEGORIES_REQUEST
    case ADD_CATEGORIES_REQUEST:
      return { ...state, loading: true };

    case ADD_CATEGORIES_REQUEST_SUCCESS:
      return { ...state, loading: false };

    case ADD_CATEGORIES_REQUEST_FAILURE:
      return { ...state, loading: false };

    // GET_CATEGORIES
    case GET_CATEGORIES:
      return { ...state, loading: true };

    case GET_CATEGORIES_SUCCESS:
      return { ...state, categories: actions.payload, loading: false };

    case GET_CATEGORIES_FAILURE:
      return { ...state, loading: false };

    // REMOVE_CATEGORIES
    case REMOVE_CATEGORIES:
      return { ...state, loading: true };

    case REMOVE_CATEGORIES_SUCCESS:
      return { ...state, loading: false };

    case REMOVE_CATEGORIES_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default CategoryReducer;
