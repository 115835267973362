import {
  GET_ORDER,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
} from "../Actions/ActionTypes";

let initialState = {
  loading: false,
  invoices: [],
};

const InvoiceReducer = (state = initialState, actions) => {
  switch (actions.type) {
    // GET_ORDER
    case GET_ORDER:
      return { ...state, loading: true };

    case GET_ORDER_SUCCESS:
      return { ...state, invoices: actions.payload, loading: false };

    case GET_ORDER_FAILURE:
      return { ...state, loading: false };

    //UPDATE_ORDER

    case UPDATE_ORDER:
      return { ...state, loading: true };

    case UPDATE_ORDER_SUCCESS:
      return {...state, loading: false };
    case UPDATE_ORDER_FAILURE:
      return {...state, loading: false };
    default:
      return state;
  }
};

export default InvoiceReducer;
