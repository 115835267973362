import { toast } from "react-toastify";
import API from "./ActionTypes";
import {
  BASEURL,
  BLOCK_INSTRUCTER,
  BLOCK_INSTRUCTER_FAILURE,
  BLOCK_INSTRUCTER_SUCCESS,
  DELETE_INSTRUCTER,
  DELETE_INSTRUCTER_FAILURE,
  DELETE_INSTRUCTER_SUCCESS,
  GET_INSTRUCTER,
  GET_INSTRUCTER_FAILURE,
  GET_INSTRUCTER_SUCCESS
} from "./ActionTypes";

export const getInstructers =
  ({ page, limit }) =>
  async (dispatch) => {
    dispatch({
      type: GET_INSTRUCTER
    });
    try {
      const res = await API.get(
        `${BASEURL}api/instructor?limit=${limit}&page=${page}`
      );
      dispatch({
        type: GET_INSTRUCTER_SUCCESS,
        payload: res.data.data
      });
    } catch (e) {
      dispatch({
        type: GET_INSTRUCTER_FAILURE
      });
      toast.error("Something went wrong !");
    }
  };

export const instructerDeleteRequest = (action) => async (dispatch) => {
  dispatch({ type: DELETE_INSTRUCTER });

  try {
    await API.delete(`${BASEURL}api/instructor/${action.deleteId}`);
    toast.success("Instructor delete successfully!");
    dispatch({ type: DELETE_INSTRUCTER_SUCCESS, payload: action.deleteId });
    action.callback();
  } catch (error) {
    dispatch({ type: DELETE_INSTRUCTER_FAILURE });
    toast.error("Something went wrong !");
  }
};

export const instructerBlockRequest =
  (id, key, formData) => async (dispatch) => {
    dispatch({ type: BLOCK_INSTRUCTER });
    try {
      await API.patch(`${BASEURL}api/instructor/blkunblk/${id}`, formData);
      dispatch(getInstructers({ page: 1, limit: 100 }));
      if (key === "active") {
        toast.success(
          `Instructer ${
            formData.active === 0 ? "Blocked" : "UnBlocked"
          }  successfully !`
        );
      }
      
      if (key === "verified") {
        toast.success(
          `Instructer ${
            formData.is_verified === 0 ? "UnVerified" : "Verified"
          }  successfully !`
        );
      }
      
      if (key === "cert_verified") {
        toast.success(
          `Instructer ${
            formData.is_cert_verified === 0 ? "certificate is required re-verification." : "cert is verified"
          }  successfully !`
        );
      }
      
      if (key === "priv_user") {
        toast.success(
          `Instructer ${
            formData.is_priv_user === 0 ? "is converted into an unpriviledged user" : "is converted into a priviledged user"
          }  successfully !`
        );
      }
      dispatch({ type: BLOCK_INSTRUCTER_SUCCESS, payload: id });
    } catch (error) {
      dispatch({ type: BLOCK_INSTRUCTER_FAILURE });
      toast.error("Something went wrong !");
    }
  };

export const getInstructersByStatus =
  ({ status, page, limit }) =>
  async (dispatch) => {
    dispatch({
      type: GET_INSTRUCTER
    });
    try {
      const res = await API.get(
        `${BASEURL}api/instructor/status=${status}?limit=${limit}&page=${page}`
      );
      dispatch({
        type: GET_INSTRUCTER_SUCCESS,
        payload: res.data
      });
    } catch (e) {
      dispatch({
        type: GET_INSTRUCTER_FAILURE
      });
      toast.error("Something went wrong !");
    }
  };
