import React from "react";
import { useState } from "react";
import { TfiEmail } from "react-icons/tfi";
import { Link, useNavigate } from "react-router-dom";
import ApiLoader from "../Components/ApiLoader";
import Heading from "../Components/Heading";

const ForgotPass = () => {
  const [email, setEmail] = useState("");
  let loading = false;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="flex h-screen md:flex-row-reverse shadow-lg bg-white w-full  lg:gap-5 items-center">
        <figure className="md:h-full w-full md:w-1/2 lg:w-3/5 hidden md:flex ">
          <img
            src="Assets/login-bg.jpg"
            className="h-full object-cover w-full"
            alt="register"
          />
        </figure>
        <section className="md:w-1/2 lg:w-2/5 my-4  w-full p-5 sm:w-4/5  mx-auto lg:px-10">
          {/* <Logo/> */}
          <img src="/Assets/VST1.png" alt="login" className="w-24" />
          <h3 className="text-lg my-2 uppercase font-bold">Forgot Password</h3>
          <form onSubmit={handleSubmit} className="mt-10 flex flex-col">
            {/* Email */}
            <div className="p-2.5 mb-5 border rounded flex items-center gap-3">
              <label htmlFor="email">
                <TfiEmail className="text-gray-500 text-xl" />
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.email)}
                placeholder="Your Email Address"
                className="outline-none px-3 w-full text-sm"
              />
            </div>

            {/* Remember */}
            <div className="px-1 mb-5 rounded flex justify-between items-center gap-1 sm:gap-3">
              <Link to="/" className="font-medium text-sm">
                I remember password
              </Link>
            </div>
            {/* Submit */}
            <div
              className={` mb-5 border hover:bg-transparent hover:text-color ${
                loading ? "bg-transparent" : "bg-color"
              }  text-white cursor-pointer border-color text-center hover:text-black rounded flex items-center gap-3`}
            >
              <button
                type="submit"
                // disabled={loading}
                className="w-full p-2 uppercase cursor-pointer text-center"
              >
                {loading ? <ApiLoader /> : "Send Email"}
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default ForgotPass;
