import React, { useState } from "react";
import Button from "../Components/Button";
import HOC from "../Layouts/HOC";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BiUserPlus } from "react-icons/bi";
import data from "../Components/AdminInfo";
import { useDispatch } from "react-redux";
import { AuthRegister } from "../Redux/Actions/AuthAction";

const Profile = () => {
  const [show, setShow] = useState();
  const [show1, setShow1] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const [formValues, setFormValues] = useState({
    email: data.email,
    password: data.password,
    prevpassword: data.prevpassword,
  });
  const [formInput, setFormInput] = useState({
    email: "",
    password: "",
    phone: "",
    name: "",
  });

  const dispatch = useDispatch();

  const handleClose = () => {
    setFormInput({ email: "", password: "", phone: "", name: "" });
    setIsPopup(false);
  };

  //   handleChange
  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const onChangeHandler = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  // handleSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    dispatch(AuthRegister(formInput, () => handleClose()));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center border-b py-3 justify-between">
          <h4 className="md:text-lg text-slate-900 font-bold">{data.name}</h4>
          <span className=" flex items-center gap-1.5 ">
            <BiUserPlus
              onClick={() => setIsPopup(true)}
              className="text-2xl cursor-pointer text-color"
            />{" "}
            Create Admin
          </span>
        </div>

        {/* 1 */}
        <div className=" border-b pb-5 tracking-wider my-5">
          <h3 className="text-color pb-4 font-bold uppercase">Profile</h3>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-y-5 gap-x-8">
            {/* Name */}
            <div className="flex flex-col gap-2">
              <label htmlFor="firstName" className="text-xs">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                id="firstName"
                name="name"
                type="text"
                // required
                onChange={handleChange}
                placeholder={data?.name}
                className="p-1.5 mb-5 border rounded  items-center "
              />
            </div>
            {/* Email */}
            <div className="flex flex-col gap-2">
              <label htmlFor="email" className="text-xs">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                // required
                readOnly
                disabled
                placeholder={data?.email}
                onChange={handleChange}
                className="p-1.5 mb-5 border rounded  items-center "
              />
            </div>
            {/* Phone */}
            <div className="flex  flex-col gap-2">
              <label htmlFor="phone" className="text-xs">
                Phone
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                maxLength={10}
                placeholder={data?.phone}
                onChange={handleChange}
                className="p-1.5 mb-5 border rounded  items-center "
              />
            </div>
          </div>
        </div>
        {/* 2 */}
        <div className="   tracking-wider pb-5">
          <h3 className="text-color pb-4 font-bold uppercase">
            Change Password
          </h3>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-y-5 gap-x-8">
            {/* Current Password */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Current" className="text-xs">
                Current Password
              </label>
              <div className="p-2 mb-5 border rounded flex items-center gap-3">
                <input
                  id="Current"
                  name="prevpassword"
                  type={show ? "text" : "password"}
                  onChange={handleChange}
                  placeholder="Previous Password"
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
                <span
                  onClick={() => setShow(!show)}
                  className="text-xl ml-2 text-gray-600 cursor-pointer"
                >
                  {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </div>
            <div className="md:flex hidden"></div>
            {/* Password */}
            <div className="flex flex-col gap-2">
              <label htmlFor="Password" className="text-xs">
                New Password
              </label>
              <div className="p-2 mb-5 border rounded flex items-center gap-3">
                <input
                  id="Password"
                  name="password"
                  type={show1 ? "text" : "password"}
                  onChange={handleChange}
                  placeholder="New Password"
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
                <span
                  onClick={() => setShow1(!show1)}
                  className="text-xl ml-2 text-gray-600 cursor-pointer"
                >
                  {show1 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center py-3">
          <span type="submit">
            <Button button={"Save"} />
          </span>
        </div>
      </form>

      {/* Create Admin */}

      {isPopup && (
        <section className="bg-[rgb(0,0,0,0.7)] flex justify-center items-center absolute top-0 left-0 h-full w-full z-50">
          <form
            onSubmit={(e) => handleSignUp(e)}
            className="mt-8 flex flex-col gap-4 rounded-md shadow-md bg-white p-5 w-5/6 md:w-4/5 lg:w-2/5 mx-auto "
          >
            <div className="border-b flex justify-between items-center pb-2 text-center ">
              <h1 className="md:text-2xl text-lg font-semibold  text-slate-900 uppercase ">
                Create New Admin
              </h1>
              <span
                onClick={handleClose}
                className="p-1 bg-gray-100 font-bold text-sm rounded-full cursor-pointer flex
            justify-center items-center w-8 h-8"
              >
                X
              </span>
            </div>
            {/* Name */}
            <div className="flex flex-col gap-2">
              <label htmlFor="name" className="md:text-base text-sm">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                id="name"
                name="name"
                type="text"
                required
                value={formInput.name}
                onChange={(e) => onChangeHandler(e)}
                className="bg-transparent border-indigo-400 tracking-widest  text-sm rounded-sm shadow-md outline-none py-1.5 px-2 border"
              />
            </div>
            {/* Mobile */}
            <div className="flex flex-col gap-2">
              <label htmlFor="phone" className="md:text-base text-sm">
                Phone <span className="text-red-500">*</span>
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                maxLength="10"
                required
                value={formInput.phone}
                onChange={(e) => onChangeHandler(e)}
                className="bg-transparent border-indigo-400 tracking-widest  text-sm rounded-sm shadow-md outline-none py-1.5 px-2 border"
              />
            </div>
            {/* Email */}
            <div className="flex flex-col gap-2">
              <label htmlFor="email" className="md:text-base text-sm">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                value={formInput.email}
                onChange={(e) => onChangeHandler(e)}
                className="bg-transparent border-indigo-400 tracking-widest  text-sm rounded-sm shadow-md outline-none py-1.5 px-2 border"
              />
            </div>

            {/* Password */}
            <div className="flex flex-col gap-2">
              <label htmlFor="password" className="md:text-base text-sm">
                Password <span className="text-red-500">*</span>
              </label>
              <div className="border-indigo-400 flex rounded-sm items-center py-1.5 text-sm px-2 border focus:border-indigo-600 shadow-md  focus:border-2">
                <input
                  id="password"
                  name="password"
                  required
                  type={show ? "text" : "password"}
                  value={formInput.password}
                  onChange={(e) => onChangeHandler(e)}
                  className="bg-transparent tracking-widest w-full   outline-none  "
                />
                <span
                  onClick={() => setShow(!show)}
                  className="text-xl ml-2 text-slate-900 cursor-pointer"
                >
                  {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
            </div>

            {/* Submit */}
            <button
              type="submit"
              className="py-2 mt-2 mb-4 shadow-md text-white hover:bg-indigo-700 font-medium tracking-widest bg-indigo-500"
            >
              Create New Admin
            </button>
          </form>
        </section>
      )}
    </>
  );
};

export default HOC(Profile);
