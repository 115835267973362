import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { BiEdit } from "react-icons/bi";
import { getInvoices, updateInvoice } from "../Redux/Actions/OrderAction";
import Dropdown from "../Components/Dropdown";
import ButtonLoader from "../Components/ButtonLoader";
import HOC from "../Layouts/HOC";
import Heading from "../Components/Heading";

const Invoice = () => {
  const [isSelected, setSelected] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  const [edit, setEdit] = useState(null);
  const [popup, setPopup] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [payDate, setPayDate] = useState("");

  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.InvoiceReducer.invoices);
  const loading = useSelector((state) => state.InvoiceReducer.loading);

  const handleStatus = (e) => {
    setSelected(true);
    if (e === "NAN") {
      setAllInvoices(invoices);
      setSelected(false);
    } else {
      const filteredData = invoices.filter((item) => item.paymentStatus === +e);
      setAllInvoices(filteredData);
    }
  };

  const handleEditClick = (item) => {
    setPopup(true);
    setEdit(item);
    setTransactionId(item.upi_transaction_id);
    setPayDate(item.payDate);
  };

  const handleUpdate = () => {
    dispatch(updateInvoice(edit._id, { upi_transaction_id: transactionId, payDate }));
    setPopup(false);
  };

  const columns = [
    { name: "Course", selector: (row) => row.title, sortable: true },
    { name: "Subcourse", selector: (row) => row.subcourseName, sortable: true },
    { name: "Name", selector: (row) => row.instName, sortable: true },
    { name: "Email", selector: (row) => row.instEmail },
    { name: "Phone", selector: (row) => row.instPhone },
    { name: "Amount", selector: (row) => row.amount },
    { name: "Trans_ID", selector: (row) => row.upi_transaction_id },
    { name: "PaidOn", selector: (row) => row.payDate },
    {
      name: "Actions",
      selector: (row) => (
        <div className="font-bold flex gap-3 text-xl">
          <BiEdit onClick={() => handleEditClick(row)} className="cursor-pointer text-indigo-500" />
        </div>
      ),
    },
  ];
  
  const data = (isSelected ? allInvoices : invoices).map((item) => ({
    id: item._id,
    title: item.courseName,
    subcourseName: item.subcourseName,
    instName: item.instName,
    instEmail: item.instEmail,
    instPhone: item.instPhone,
    amount: item.amount,
    upi_transaction_id: item.upi_transaction_id,
    payDate: item.payDate,
  }));

  useEffect(() => {
    dispatch(getInvoices());
  }, [dispatch]);

  return (
    <>
      <section>
        <div className="flex border-b py-2 mb-4 items-center justify-between">
          
            <Heading title={"Payout"}/>
            <Dropdown
              options={[
                { value: 1, label: "Paid" },
                { value: 0, label: "Unpaid" },
              ]}
              handleChange={(e) => handleStatus(e)}
            />
         
        </div>
        {loading ? (
          <ButtonLoader />
        ) : (
          <DataTable columns={columns} data={data} pagination />
        )}
      </section>

      {popup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded shadow-md w-96">
            <h2 className="text-2xl mb-4">Edit Invoice</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">UPI Transaction ID</label>
              <input
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Paid Date On</label>
              <input
                type="datetime-local"
                value={payDate}
                onChange={(e) => setPayDate(e.target.value)}
                className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleUpdate}
                className="bg-indigo-500 text-white px-4 py-2 rounded shadow-md hover:bg-blue-600 mr-2"
              >
                Update
              </button>
              <button
                onClick={() => setPopup(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded shadow-md hover:bg-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HOC(Invoice);
