import axios from "axios";
import { toast } from "react-toastify";
import {
  BASEURL,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  ADMIN_BASEURL
} from "./ActionTypes";

// REGISTER
export const AuthRegister = (formValues, cb) => async (dispatch) => {
  dispatch({ type: REGISTER });
  try {
    await axios.post(`${ADMIN_BASEURL}sign-up`, formValues).then((res) => {
      if (res.status === 201) {
        alert(res.data.message);
        dispatch({ type: REGISTER_SUCCESS });
        cb();
      }
    });
  } catch (error) {
    dispatch({ type: REGISTER_FAIL });
  }
};

// LOGIN
export const AuthLogin = (formValues, cb) => async (dispatch) => {
  dispatch({ type: LOGIN });
  try {
    await axios.post(`${ADMIN_BASEURL}login`, formValues).then((res) => {
      if (res.data.message === "Admin logged in successfully") {
        toast.success(res.data.message);
        localStorage.setItem("access_token", res.data.token);
        localStorage.setItem("admin_info", JSON.stringify(res.data.data));
        cb();
      } else {
        toast.error(res.data.message);
      }
      dispatch({ type: LOGIN_SUCCESS });
    });
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
  }
};
