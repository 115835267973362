import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

const HOC = (WComponent) => {
  return function Component() {
    const [responsive, setResponsive] = useState(true);
    return (
      <>
        <section className="flex overflow-hidden text-slate-800 bg-gray-100 h-screen">
          <div
            className={` ${
              responsive ? "w-16" : "md:w-60 w-16 "
            }  duration-75  overflow-y-auto h-full transition-all   `}
          >
            <Sidebar responsive={responsive} setResponsive={setResponsive} />
          </div>
          <div
            className={`overflow-y-auto ${
              responsive ? "w-full" : "md:w-4/5 w-full lg:w-5/6"
            }   h-full px-3  duration-75 transition-all`}
          >
            <Header />
            <div className="p-3  bg-white rounded shadow">
              <WComponent />
            </div>
          </div>
        </section>
      </>
    );
  };
};

export default HOC;
