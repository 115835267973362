import React from "react";
import { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { addLanguage } from "../../Redux/Actions/LanguageAction";
import AdminInfo from "../AdminInfo";

const AddLanguage = ({ setPopup, loading }) => {
  const [formData, setFormData] = useState({
    name: "",
    created_by: AdminInfo._id,
  });

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addLanguage(formData, handleClose));
  };

  const handleClose = () => {
    setFormData({ ...formData, name: "" });
    setPopup(false);
  };
  return (
    <section className="lg:w-1/2 md:w-1/3 w-80 sm:w-2/3   sm:mx-0 ">
      {/* Top */}
      <div className="bg-gray-100 flex items-center justify-between text-sm tracking-wider font-semibold rounded-t-md py-1.5 px-3">
        <span className=""> Language</span>

        <IoIosClose
          onClick={handleClose}
          className="text-slate-800 cursor-pointer text-3xl"
        />
      </div>

      {/* Form */}
      <form
        onSubmit={handleSubmit}
        className="bg-white p-3 md:p-4 flex flex-col md:grid  gap-4"
      >
        {/* Category Name */}
        <div className="flex flex-col gap-2">
          <label htmlFor="languageName" className="text-xs">
            Title <span className="text-red-500">*</span>
          </label>
          <input
            id="languageName"
            name="languageName"
            type="text"
            required
            value={formData.name}
            onChange={(event) =>
              setFormData({ ...formData, name: event.target.value })
            }
            className="bg-transparent border-indigo-400 tracking-widest  text-sm rounded-sm shadow-md outline-none py-1.5 px-2 border"
          />
        </div>

        <div className="flex mt-4 gap-5">
          <input
            type="submit"
            value={loading ? "working..." : "Add"}
            className=" text-white cursor-pointer py-1.5 px-3 w-28 md:px-4 shadow rounded tracking-widest bg-indigo-500 hover:bg-indigo-600 "
          />
        </div>
      </form>
    </section>
  );
};

export default AddLanguage;
