import {
  BLOCK_INSTRUCTER,
  BLOCK_INSTRUCTER_FAILURE,
  BLOCK_INSTRUCTER_SUCCESS,
  DELETE_INSTRUCTER,
  DELETE_INSTRUCTER_FAILURE,
  DELETE_INSTRUCTER_SUCCESS,
  GET_INSTRUCTER,
  GET_INSTRUCTER_FAILURE,
  GET_INSTRUCTER_SUCCESS,
} from "../Actions/ActionTypes";

let initialState = {
  loading: false,
  instructers: [],
};

const InstructerReducer = (state = initialState, actions) => {
  switch (actions.type) {
    // BLOCK_INSTRUCTER
    case BLOCK_INSTRUCTER:
      return { ...state, loading: true };

    case BLOCK_INSTRUCTER_SUCCESS:
      return { ...state, loading: false };

    case BLOCK_INSTRUCTER_FAILURE:
      return { ...state, loading: false };

    // GET_INSTRUCTER
    case GET_INSTRUCTER:
      return { ...state, loading: true };

    case GET_INSTRUCTER_SUCCESS:
      return { ...state, instructers: actions.payload, loading: false };

    case GET_INSTRUCTER_FAILURE:
      return { ...state, loading: false };

    // DELETE_INSTRUCTER
    case DELETE_INSTRUCTER:
      return { ...state, loading: true };

    case DELETE_INSTRUCTER_SUCCESS:
      return { ...state, loading: false };

    case DELETE_INSTRUCTER_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default InstructerReducer;
