import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Heading from "../Components/Heading";
import HOC from "../Layouts/HOC";
import {
  getEnrolled,
  getEnrolledByCourseId,
} from "../Redux/Actions/EnrollAction";
import DataTable from "react-data-table-component";
import { IoIosArrowBack } from "react-icons/io";
import ButtonLoader from "../Components/ButtonLoader";

const Enrollment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course_id = new URLSearchParams(document.location.search).get(
    "course_id"
  );
  const loading = useSelector((state) => state.EnrollmentReducer.loading);
  const enrolled = useSelector((state) => state.EnrollmentReducer.enrollments);

  //  table data
  const columns = [
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Name</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Email</div>
      ),
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Course
        </div>
      ),
      selector: (row) => row.course,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          Sub Course
        </div>
      ),
      selector: (row) => row.subcourse,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">Price</div>
      ),
      selector: (row) => row.price,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">Phone</div>
      ),
      selector: (row) => row.phone,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          Enroll Date
        </div>
      ),
      selector: (row) => row.enrolldate,
      sortable: true,
    },
  ];

  const data = enrolled?.map((item) => {
    return {
      id: item._id,
      title: <div className="capitalize">{item?.user?.name}</div>,
      email: item?.user?.email,
      course: item?.course?.name,
      subcourse: item?.subcourse?.name,
      phone: item?.user?.phone,
      enrolldate: item?.createdAt,
    };
  });

  useEffect(() => {
    if (course_id) {
      dispatch(getEnrolledByCourseId(course_id));
    } else {
      dispatch(getEnrolled());
    }
  }, [dispatch, course_id]);
  return (
    <>
      <div className="flex border-b py-2 mb-4 items-center justify-between">
        <Heading title={`Enrollments ${course_id ? "By Course" : ""}`} />
        {course_id && (
          <div
            className=" md:text-base text-xs sm:text-sm text-white cursor-pointer  w-8 h-8 flex justify-center items-center shadow rounded-full tracking-widest bg-indigo-500 hover:bg-indigo-600 "
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack />
          </div>
        )}
      </div>

      {loading ? (
        <ButtonLoader />
      ) : (
        <DataTable columns={columns} data={data} pagination />
      )}
    </>
  );
};
export default HOC(Enrollment);
