import React, { useEffect } from "react";
import HOC from "../Layouts/HOC";
import { BiDownload } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  getInstructers,
  instructerBlockRequest,
  instructerDeleteRequest,
} from "../Redux/Actions/InstructorAction";
import Modal from "../Components/Modal";
import { useState } from "react";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";
import Heading from "../Components/Heading";
import Dropdown from "../Components/Dropdown";
import ButtonLoader from "../Components/ButtonLoader";

const pageSize = 100;

const Instructer = () => {
  const [isSelected, setSelected] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [isdelete, setDelete] = useState(false);
  const [allInstructer, setAllInstructer] = useState();

  const dispatch = useDispatch();

  const instructersDetail = useSelector(
    (state) => state.InstructerReducer.instructers
  );
  const loading = useSelector((state) => state.InstructerReducer.loading);

  const handleCerts = (certs) => {
    let mat = certs;
    if (mat !== "")
    {
      let base_path = process.env.REACT_APP_BASE_URL;
      mat = mat.slice(2);
      mat = process.env.REACT_APP_BASE_URL + mat;
      window.open(mat);  
    }
  };

  //  table data
  const columns = [
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Name</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Email</div>
      ),
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Phone</div>
      ),
      selector: (row) => row.phone,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Active
        </div>
      ),
      selector: (row) => row.active,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Verified
        </div>
      ),
      selector: (row) => row.verified,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Cert Verified
        </div>
      ),
      selector: (row) => row.cert_verified,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Priv User
        </div>
      ),
      selector: (row) => row.priv_user,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          BIO DATA
        </div>
      ),
      selector: (row) => row.certs,
    },
  ];

  const data = (isSelected ? allInstructer : instructersDetail).map((item) => {
    return {
      id: item._id,
      title: (
        <Link
          to={"/instructor_profile"}
          state={item}
          className="capitalize text-color underline"
        >
          {item.name}
        </Link>
      ),
      email: item.email,
      phone: item.phone,
      active: (
        <label
          for={item._id}
          className="inline-flex items-center space-x-4 cursor-pointer text-gray-100"
        >
          <span className="relative">
            <input
              id={item._id}
              type="checkbox"
              checked={item.active !== 0}
              className="hidden peer"
              onChange={() => {
                {
                  dispatch(
                    instructerBlockRequest(item._id, "active", {
                      active: item.active === 0 ? 1 : 0,
                    })
                  );
                }
              }}
            />
            <div className="w-10 h-6 rounded-full shadow-inner bg-gray-400 peer-checked:bg-sky-300"></div>
            <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-white"></div>
          </span>
        </label>
      ),
      verified: (
        <label
          for={`${item._id}${item.name}`}
          className="inline-flex items-center space-x-4 cursor-pointer text-gray-100"
        >
          <span className="relative">
            <input
              id={`${item._id}${item.name}`}
              type="checkbox"
              checked={item.is_verified !== 0}
              className="hidden peer"
              onChange={() => {
                {
                  dispatch(
                    instructerBlockRequest(item._id, "verified", {
                      is_verified: item.is_verified === 0 ? 1 : 0,
                    })
                  );
                }
              }}
            />
            <div className="w-10 h-6 rounded-full shadow-inner bg-gray-400 peer-checked:bg-sky-300"></div>
            <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-white"></div>
          </span>
        </label>
      ),
      cert_verified: (
        <label
          for={`${item.name}`}
          className="inline-flex items-center space-x-4 cursor-pointer text-gray-100"
        >
          <span className="relative">
            <input
              id={`${item.name}`}
              type="checkbox"
              checked={item.is_cert_verified !== 0}
              className="hidden peer"
              onChange={() => {
                {
                  dispatch(
                    instructerBlockRequest(item._id, "cert_verified", {
                      is_cert_verified: item.is_cert_verified === 0 ? 1 : 0,
                    })
                  );
                }
              }}
            />
            <div className="w-10 h-6 rounded-full shadow-inner bg-gray-400 peer-checked:bg-sky-300"></div>
            <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-white"></div>
          </span>
        </label>
      ),
      priv_user: (
        <label
          for={item.email}
          className="inline-flex items-center space-x-4 cursor-pointer text-gray-100"
        >
          <span className="relative">
            <input
              id={item.email}
              type="checkbox"
              checked={item.is_priv_user !== 0}
              className="hidden peer"
              onChange={() => {
                {
                  dispatch(
                    instructerBlockRequest(item._id, "priv_user", {
                      is_priv_user: item.is_priv_user === 0 ? 1 : 0,
                    })
                  );
                }
              }}
            />
            <div className="w-10 h-6 rounded-full shadow-inner bg-gray-400 peer-checked:bg-sky-300"></div>
            <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-white"></div>
          </span>
        </label>
      ),
      certs: (
        <div>
          <BiDownload
            onClick={() => handleCerts(item.certs)}

            title="Bio Data"
            className="cursor-pointer font-bold flex gap-3 text-xl md:text-2xl text-color"
          />
        
        </div>
      ),

    };
  });

  // Delete
  const handleDeleteInstructors = async (id) => {
    dispatch(
      instructerDeleteRequest({ deleteId, callback: () => setDelete(false) })
    );
  };

  const handleStatus = (e) => {
    setSelected(true);
    if (e === "NAN") {
      setAllInstructer(instructersDetail);
      setSelected(false);
    } else {
      const filteredData = instructersDetail.filter(
        (item) => item.active === +e
      );
      setAllInstructer(filteredData);
    }
  };

  // useEffescts
  useEffect(() => {
    dispatch(getInstructers({ page: pageCount, limit: pageSize }));
  }, [dispatch, pageCount]);

  return (
    <>
      {/* Top */}
      <div className="flex border-b py-2 mb-4 items-center justify-between">
        <Heading title={"Instructers"} />

        <Dropdown
          options={[
            { value: 1, label: "Active" },
            { value: 0, label: "InActive" },
          ]}
          handleChange={(e) => handleStatus(e)}
        />
      </div>

      {/* {loading ? (
        <ButtonLoader />
      ) : ( */}
      <DataTable columns={columns} data={data} pagination sorting />
      {/* )} */}

      {isdelete && (
        <Modal
          handleDeleteInstructors={handleDeleteInstructors}
          setDelete={setDelete}
          loading={loading}
        />
      )}
    </>
  );
};

export default HOC(Instructer);
