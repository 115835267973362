import React from "react";
import HOC from "../Layouts/HOC";

const Home = () => {
  return (
    <>
      <section className="tracking-wider h-full">
        <div className="flex flex-col md:w-2/3 h-full py-5 gap-3 ">
          <h3 className="text-2xl md:text-4xl lg:text-5xl">Welcome 👋</h3>
          <p className="text-slate-500 ">
            We hope you are making progress on your project! Feel free to read
            the latest news about E-learn.We are giving our best to improve the
            product based on your feedback.
          </p>
        </div>
      </section>
    </>
  );
};

export default HOC(Home);
