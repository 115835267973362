import React, { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HOC from "../Layouts/HOC";
import { getCourcesByInstructor } from "../Redux/Actions/CourceAction";
import { IoIosArrowBack } from "react-icons/io";
import DataTable from "react-data-table-component";
import Heading from "../Components/Heading";
import ButtonLoader from "../Components/ButtonLoader";

const pageSize = 100;

const InstructorProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const loading = useSelector((state) => state.CourseReducer.loading);
  const course = useSelector((state) => state.CourseReducer.courses);
  const instructer = location.state;

  useEffect(() => {
    dispatch(
      getCourcesByInstructor({ id: instructer?._id, page: 1, limit: pageSize })
    );
  }, [dispatch, instructer?._id]);

  //  table data
  const columns = [
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Name</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Course Type
        </div>
      ),
      selector: (row) => row.type,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Description
        </div>
      ),
      selector: (row) => row.description,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">Image</div>
      ),
      selector: (row) => row.image,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Price</div>
      ),
      selector: (row) => row.price,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Sub Courses
        </div>
      ),
      selector: (row) => row.subCourse,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Enrolled
        </div>
      ),
      selector: (row) => row.enrolled,
      sortable: true,
    },
  ];

  const data = course?.data?.map((item, index) => {
    return {
      id: item._id,
      title: item.name,
      type: <span className="uppercase text-sm"> {item.course_type}</span>,
      description: item.description,
      image: (
        <img
          src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
          alt={item.name}
          className="w-32 my-3 object-cover object-center"
        />
      ),
      price: <div>Rs. {item.price}</div>,
      subCourse: (
        <Link
          to={`/sub_courses?course_id=${item._id}`}
          className="text-indigo-600 uppercase font-semibold tracking-wider underline"
        >
          View
        </Link>
      ),

      enrolled: (
        <Link
          to={`/enrollments?course_id=${item._id}`}
          className="text-indigo-600 uppercase font-semibold tracking-wider underline"
        >
          View
        </Link>
      ),
    };
  });

  return (
    <>
      <div>
        <div className="flex border-b py-2 mb-4 items-center justify-between">
          <Heading title={"Intructor Profile"} />
          <div
            className=" md:text-base text-xs sm:text-sm text-white cursor-pointer  w-8 h-8 flex justify-center items-center shadow rounded-full tracking-widest bg-indigo-500 hover:bg-indigo-600 "
            onClick={() => navigate(`/instructors`)}
          >
            <IoIosArrowBack />
          </div>
        </div>

        <div className="lg:my-4 flex items-center lg:flex-row flex-col gap-4">
          <figure className="lg:w-72 lg:h-72 w-40  md:h-60 h-40  md:w-60 mx-auto rounded-full shadow">
            <img
              src="/Assets/user.png"
              alt={instructer?.name}
              className="w-full h-full rounded-full object-cover"
            />
          </figure>
          <div className="flex lg:w-3/5 text-sm flex-col capitalize gap-2">
            <div className="text-slate-500 uppercase font-semibold tracking-wider md:text-lg">
              Name :{" "}
              <span className="text-slate-900 pl-2 md:pl-5 font-semibold">
                {instructer?.name}
              </span>
            </div>

            <div className="text-slate-500 uppercase font-semibold tracking-wider md:text-lg">
              Email :{" "}
              <span className="text-slate-900 pl-2 md:pl-5 font-semibold">
                {instructer?.email}
              </span>
            </div>
            {/* phone */}
            {instructer.phone && (
              <div className="text-slate-500 uppercase font-semibold tracking-wider md:text-lg">
                Phone :{" "}
                <span className="text-slate-900 pl-2 md:pl-5 font-semibold">
                  {instructer?.phone}
                </span>
              </div>
            )}
            {/* city */}
            {instructer.city && (
              <div className="text-slate-500 uppercase font-semibold tracking-wider md:text-lg">
                City :{" "}
                <span className="text-slate-900 pl-2 md:pl-5 font-semibold">
                  {instructer?.city}
                </span>
              </div>
            )}
            {/* state */}
            {instructer.state && (
              <div className="text-slate-500 uppercase font-semibold tracking-wider md:text-lg">
                State :{" "}
                <span className="text-slate-900 pl-2 md:pl-5 font-semibold">
                  {instructer?.state}
                </span>
              </div>
            )}
            {/* joined */}
            {instructer.createdAt && (
              <div className="text-slate-500 uppercase font-semibold tracking-wider md:text-lg">
                Joined Date :{" "}
                <span className="text-slate-900 pl-2 md:pl-5 font-semibold">
                  {instructer?.createdAt?.slice(0, 10)}
                </span>
              </div>
            )}
          </div>
        </div>

        <h3 className="py-3 border-b my-3 text-xl font-medium">All Courses</h3>

        {loading ? (
          <ButtonLoader />
        ) : (
          <DataTable columns={columns} data={data} pagination sorting />
        )}
      </div>
    </>
  );
};

export default HOC(InstructorProfile);
