import { toast } from "react-toastify";
import API from "./ActionTypes";
import {
  BASEURL,
  GET_SESSIONS,
  GET_SESSIONS_FAILURE,
  GET_SESSIONS_SUCCESS
} from "./ActionTypes";

export const getAllSessions = () => async (dispatch) => {
  dispatch({
    type: GET_SESSIONS
  });
  try {
    const res = await API.get(`${BASEURL}api/session`);
    if (res.status === 200) {
      dispatch({
        type: GET_SESSIONS_SUCCESS,
        payload: res.data.data
      });
    }
  } catch (error) {
    dispatch({ type: GET_SESSIONS_FAILURE });
    toast.error("Something went wrong");
  }
};

export const getSessionsBySubcources = (id) => async (dispatch) => {
  dispatch({
    type: GET_SESSIONS
  });
  try {
    const res = await API.get(`${BASEURL}api/session/subcourse/${id}`);
    if (res.status === 200) {
      dispatch({
        type: GET_SESSIONS_SUCCESS,
        payload: res.data.data
      });
    }
  } catch (error) {
    dispatch({ type: GET_SESSIONS_FAILURE });
    toast.error("Something went wrong");
  }
};
