import React, { useState, useEffect } from "react";
import { BiTrash } from "react-icons/bi";
import Modal from "../Components/Modal";
import HOC from "../Layouts/HOC";
import Heading from "../Components/Heading";
import DataTable from "react-data-table-component";
import Button from "../Components/Button";
import AddLanguage from "../Components/Modals/AddLanguage";
import { useDispatch, useSelector } from "react-redux";
import { getLanguages, removeLanguage } from "../Redux/Actions/LanguageAction";
import ButtonLoader from "../Components/ButtonLoader";

const Languages = () => {
  const [langId, setLangId] = useState();
  const [popup, setPopup] = useState(false);
  const [isDelete, setDelete] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.LanguageReducer.loading);
  const languagesData = useSelector((state) => state.LanguageReducer.languages);

  const handleDeleteLanguage = () => {
    dispatch(removeLanguage(langId, () => setDelete(false)));
  };

  const handleConfirmDelete = (id) => {
    setLangId(id);
    setDelete(true);
  };

  //  table data
  const columns = [
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Name</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          Created At
        </div>
      ),
      selector: (row) => row.createdAt,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          Actions
        </div>
      ),
      selector: (row) => row.actions,
    },
  ];

  const data = languagesData?.map((item, index) => {
    return {
      id: item._id,
      title: <div className="capitalize">{item.name}</div>,
      createdAt: (
        <div className="capitalize"> {item?.createdAt?.slice(0, 10)}</div>
      ),
      actions: (
        <div className="font-bold flex gap-3 text-xl">
          <BiTrash
            onClick={() => handleConfirmDelete(item._id)}
            className="cursor-pointer text-red-600"
          />
        </div>
      ),
    };
  });

  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);

  return (
    <>
      {/* Top */}
      <div className="flex border-b py-2 mb-4 items-center justify-between">
        <Heading title={"Languages"} />

        <div className="" onClick={() => setPopup(true)}>
          <Button button={"Add New"} />
        </div>
      </div>

      {loading ? (
        <ButtonLoader />
      ) : (
        <DataTable columns={columns} data={data} pagination />
      )}

      {/* To Add Language */}
      <section
        className={`absolute top-0 ${
          popup ? "flex" : "hidden"
        }  justify-center z-50 items-center left-0 h-screen w-full bg-[rgb(0,0,0,0.7)]`}
      >
        <AddLanguage loading={loading} setPopup={setPopup} />
      </section>

      {/* To delete */}
      {isDelete && (
        <Modal
          handleDeleteInstructors={handleDeleteLanguage}
          setDelete={setDelete}
          loading={loading}
        />
      )}
    </>
  );
};

export default HOC(Languages);
