import AuthReducer from "./AuthReducer";
import { combineReducers } from "redux";
import StudentReducer from "./StudentReducer";
import InstructerReducer from "./InstructerReducer";
import CategoryReducer from "./CategoryReducer";
import CourseReducer from "./CourseReducer";
import SessionReducer from "./SessionReducer";
import EnrollmentReducer from "./EnrollmentReducer";
import InvoiceReducer from "./InvoiceReducer";
import LanguageReducer from "./LanguageReducer";

const Reducer = combineReducers({
  AuthReducer,
  StudentReducer,
  InstructerReducer,
  CategoryReducer,
  CourseReducer,
  SessionReducer,
  EnrollmentReducer,
  InvoiceReducer,
  LanguageReducer,
});

export default Reducer;
