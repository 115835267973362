import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import VST from "../assets/VST.png";
// React-icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { TfiEmail, TfiLock } from "react-icons/tfi";

// import Heading from "./Heading";
import { useDispatch, useSelector } from "react-redux";
import { AuthLogin } from "../Redux/Actions/AuthAction";
import ApiLoader from "../Components/ApiLoader";

const Login = () => {
  const [show, setShow] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formInput, setFormInput] = useState({
    email: "",
    password: "",
  });

  const loading = useSelector((state) => state.AuthReducer.loading);
  // Login
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(AuthLogin(formInput, () => navigate("/dashboard")));
  };

  return (
    <div className="flex h-screen md:flex-row-reverse shadow-lg bg-white w-full  lg:gap-5 items-center">
      <figure className="md:h-full w-full md:w-1/2 lg:w-3/5 hidden md:flex ">
        <img
          src="Assets/login-bg-2.jpg"
          className="h-full object-cover w-full"
          alt="register"
        />
      </figure>
      <section className="md:w-1/2 lg:w-2/5 my-4  w-full p-5 sm:w-4/5  mx-auto lg:px-10">
        {/* <Logo/> */}
        <img src="/Assets/VST1.png" alt="login" className="w-24" />
        <h3 className="text-lg my-2 uppercase font-bold">
          Welcome to dashboard
        </h3>
        <form onSubmit={handleSubmit} className="mt-10 flex flex-col">
          {/* Email */}
          <div className="p-2.5 mb-5 border rounded flex items-center gap-3">
            <label htmlFor="email">
              <TfiEmail className="text-gray-500 text-xl" />
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              value={formInput.email}
              onChange={(e) =>
                setFormInput({ ...formInput, email: e.target.value })
              }
              placeholder="Your Email Address"
              className="outline-none px-3 w-full text-sm"
            />
          </div>
          {/* Password */}
          <div className="p-2.5 mb-5 border rounded flex items-center gap-3">
            <label htmlFor="password">
              <TfiLock className="text-gray-500 text-xl" />
            </label>
            <input
              id="password"
              name="password"
              required
              type={show ? "text" : "password"}
              value={formInput.password}
              onChange={(e) =>
                setFormInput({ ...formInput, password: e.target.value })
              }
              className="outline-none px-3 w-full text-sm"
              placeholder="Password"
            />
            <span
              onClick={() => setShow(!show)}
              className="text-gray-500 text-xl"
            >
              {show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </span>
          </div>
          {/* Forgot */}
          <div className="px-1 mb-5 rounded flex justify-between items-center gap-1 sm:gap-3">
            <Link to="/forgot" className="font-medium text-sm">
              Forgot Your Password ?
            </Link>
            <Link to="/sign-up" className="font-medium text-sm">
              First Time User? Sign up
            </Link>            
          </div>
          {/* Forgot */}       
          {/* Submit */}
          <div
            className={` mb-5 border hover:bg-transparent hover:text-color ${
              loading ? "bg-transparent" : "bg-color"
            }  text-white cursor-pointer border-color text-center hover:text-black rounded flex items-center gap-3`}
          >
            <button
              type="submit"
              disabled={loading}
              className="w-full p-2 uppercase cursor-pointer text-center"
            >
              {loading ? <ApiLoader /> : "Login"}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Login;
