import { toast } from "react-toastify";
import API, { BASEURL } from "./ActionTypes";
import {
  BLOCK_STUDENT,
  BLOCK_STUDENT_FAILURE,
  BLOCK_STUDENT_SUCCESS,
  DELETE_STUDENT,
  DELETE_STUDENT_FAILURE,
  DELETE_STUDENT_SUCCESS,
  GET_STUDENT,
  GET_STUDENT_FAILURE,
  GET_STUDENT_SUCCESS
} from "./ActionTypes";

let url = `${process.env.REACT_APP_BASE_URL}`;

// Get Students
export const AllStudents =
  ({ page, limit }) =>
  async (dispatch) => {
    dispatch({
      type: GET_STUDENT
    });
    try {
      const res = await API.get(`${url}api/user?limit=${limit}&page=${page}`);
      if (res.status === 200) {
        dispatch({
          type: GET_STUDENT_SUCCESS,
          payload: res.data
        });
      }
    } catch (error) {
      dispatch({
        type: GET_STUDENT_FAILURE
      });
      toast.error("Something went wrong !");
    }
  };

// Delete Student
export const DeleteStudent = (action) => async (dispatch) => {
  dispatch({
    type: DELETE_STUDENT
  });
  let url = `${process.env.REACT_APP_BASE_URL}api/user`;
  try {
    const res = await API.delete(`${url}/${action.deleteId}`);
    if (res.data.status === 200) {
      dispatch(AllStudents({ page: 1, limit: 100 }));
      toast.success("User delete successfully");
      dispatch({
        type: DELETE_STUDENT_SUCCESS
      });
      action.callback();
    }
  } catch (error) {
    dispatch({ type: DELETE_STUDENT_FAILURE });
    toast.error("Something went wrong !");
  }
};

// Block Student
export const BlockStudent = (id, key, formData) => async (dispatch) => {
  dispatch({
    type: BLOCK_STUDENT
  });

  try {
    await API.put(`${BASEURL}api/user/${id}`, formData);
    dispatch(AllStudents({ page: 1, limit: 100 }));
    if (key === "active") {
      toast.success(
        `User ${
          formData.active === 0 ? "Blocked" : "UnBlocked"
        }  successfully !`
      );
    } else {
      toast.success(
        `User ${
          formData.is_verified === 0 ? "UnVerified" : "Verified"
        }  successfully !`
      );
    }
    if (key === "priv_user") {
      toast.success(
        `User ${
          formData.is_priv_user === 0 ? "is converted into an unpriviledged user" : "is converted into a priviledged user"
        }  successfully !`
      );
    }
    dispatch({
      type: BLOCK_STUDENT_SUCCESS,
      payload: id
    });
  } catch (error) {
    dispatch({ type: BLOCK_STUDENT_FAILURE });
    toast.error("Something went wrong !");
  }
};

export const GetStudentByStatus = (page, limit, status) => async (dispatch) => {
  dispatch({
    type: GET_STUDENT
  });
  try {
    const res = await API.get(
      `${url}api/user/${status}?limit=${limit}&page=${page}`
    );
    if (res.status === 200) {
      dispatch({
        type: GET_STUDENT_SUCCESS,
        payload: res.data.data
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STUDENT_FAILURE
    });
    toast.error("Something went wrong !");
  }
};
