import React from "react";

const Button = ({ button }) => {
  return (
    <>
      <button className=" md:text-base text-xs sm:text-sm text-white py-1.5  w-24 md:w-28 shadow rounded tracking-widest bg-color  ">
        {button}
      </button>
    </>
  );
};

export default Button;
