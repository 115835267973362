import React from "react";
import { useNavigate } from "react-router-dom";
import AdminInfo from "../Components/AdminInfo";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-100 z-10 sticky top-0 py-3">
      <header className="px-4  shadow rounded py-2  bg-white flex items-center justify-between">
        <div className="">
          <img src="/Assets/VST.png" alt="admin" className="w-8 sm:w-9" />
        </div>
        <div className="bg-white">
          <div className=" bg-white justify-center gap-2.5 md:justify-start flex items-center w-full bottom-0 left-0">
            <img
              title={AdminInfo?.name}
              onClick={() => navigate("/profile")}
              src="/Assets/user.png"
              alt="admin"
              className="md:w-10 md:h-10 sm:w-9 sm:h-9 w-8 h-8  object-cover rounded-full shadow-lg cursor-pointer object-center"
            />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
