import React from "react";

const Heading = ({ title }) => {
  return (
    <h1 className="md:text-2xl text-lg font-semibold  text-slate-900 uppercase ">
      {title}
    </h1>
  );
};

export default Heading;
