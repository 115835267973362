import {
  GET_LANGUAGE,
  GET_LANGUAGE_FAILURE,
  GET_LANGUAGE_SUCCESS,
  REMOVE_LANGUAGE,
  REMOVE_LANGUAGE_SUCCESS,
  REMOVE_LANGUAGE_FAILURE,
  ADD_LANGUAGE_REQUEST,
  ADD_LANGUAGE_REQUEST_SUCCESS,
  ADD_LANGUAGE_REQUEST_FAILURE,
  UPDATE_LANGUAGE_REQUEST,
  UPDATE_LANGUAGE_REQUEST_SUCCESS,
  UPDATE_LANGUAGE_REQUEST_FAILURE,
} from "../Actions/ActionTypes";

let initialState = {
  loading: false,
  languages: [],
};

const LanguageReducer = (state = initialState, actions) => {
  switch (actions.type) {
    // GET_LANGUAGE
    case GET_LANGUAGE:
      return { ...state, loading: true };

    case GET_LANGUAGE_SUCCESS:
      return { ...state, languages: actions.payload, loading: false };

    case GET_LANGUAGE_FAILURE:
      return { ...state, loading: false };

    // REMOVE_LANGUAGE
    case REMOVE_LANGUAGE:
      return { ...state, loading: true };

    case REMOVE_LANGUAGE_SUCCESS:
      return { ...state, loading: false };

    case REMOVE_LANGUAGE_FAILURE:
      return { ...state, loading: false };

    // ADD_LANGUAGE_REQUEST
    case ADD_LANGUAGE_REQUEST:
      return { ...state, loading: true };

    case ADD_LANGUAGE_REQUEST_SUCCESS:
      return { ...state, loading: false };

    case ADD_LANGUAGE_REQUEST_FAILURE:
      return { ...state, loading: false };

    // UPDATE_LANGUAGE_REQUEST
    case UPDATE_LANGUAGE_REQUEST:
      return { ...state, loading: true };

    case UPDATE_LANGUAGE_REQUEST_SUCCESS:
      return { ...state, loading: false };

    case UPDATE_LANGUAGE_REQUEST_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default LanguageReducer;
