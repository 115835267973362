import { toast } from "react-toastify";
import API from "./ActionTypes";
import {
  BASEURL,
  GET_LANGUAGE,
  GET_LANGUAGE_FAILURE,
  GET_LANGUAGE_SUCCESS,
  REMOVE_LANGUAGE,
  REMOVE_LANGUAGE_SUCCESS,
  REMOVE_LANGUAGE_FAILURE,
  ADD_LANGUAGE_REQUEST,
  ADD_LANGUAGE_REQUEST_SUCCESS,
  ADD_LANGUAGE_REQUEST_FAILURE,
  UPDATE_LANGUAGE_REQUEST,
  UPDATE_LANGUAGE_REQUEST_SUCCESS,
  UPDATE_LANGUAGE_REQUEST_FAILURE,
} from "./ActionTypes";

// Get
export const getLanguages = () => async (dispatch) => {
  dispatch({
    type: GET_LANGUAGE,
  });
  try {
    const res = await API.get(`${BASEURL}api/language`);
    dispatch({
      type: GET_LANGUAGE_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: GET_LANGUAGE_FAILURE,
    });
    toast.error("Something went wrong !");
  }
};

// Add Language
export const addLanguage = (formData, callback) => async (dispatch) => {
  dispatch({
    type: ADD_LANGUAGE_REQUEST,
  });

  try {
    const res = await API.post(`${BASEURL}api/language`, formData);
    if (res.status === 200) {
      dispatch(getLanguages());
      dispatch({
        type: ADD_LANGUAGE_REQUEST_SUCCESS,
        payload: res.data.data,
      });
      toast.success(res.data.message);
      return callback();
    }
  } catch (e) {
    dispatch({
      type: ADD_LANGUAGE_REQUEST_FAILURE,
    });
    toast.error("Something went wrong !");
  }
};

// Update Language
export const updateLanguage =
  (formData, token, id, callback) => async (dispatch) => {
    dispatch({
      type: UPDATE_LANGUAGE_REQUEST,
    });

    try {
      const payload = new FormData();
      payload.append("name", formData.name);
      payload.append("image", formData.image);
      payload.append("created_by", token._id);
      const res = await API.patch(`${BASEURL}api/language/${id}`, payload);
      if (res.status === 200) {
        dispatch(getLanguages());
        toast.success("Category update Successfully");
      }
      dispatch({
        type: UPDATE_LANGUAGE_REQUEST_SUCCESS,
      });
      return callback();
    } catch (e) {
      dispatch({
        type: UPDATE_LANGUAGE_REQUEST_FAILURE,
      });
      toast.error("Something went wrong !");
    }
  };

// Delete Langugae
export const removeLanguage = (id, cb) => async (dispatch) => {
  dispatch({
    type: REMOVE_LANGUAGE,
  });
  try {
    const res = await API.delete(`${BASEURL}api/language/${id}`);
    if (res.data.status === 200) {
      dispatch(getLanguages());
      toast.success(res.data.message);
      dispatch({
        type: REMOVE_LANGUAGE_SUCCESS,
      });
      cb();
    }
  } catch (e) {
    dispatch({
      type: REMOVE_LANGUAGE_FAILURE,
    });
    toast.error("Something went wrong !");
  }
};
