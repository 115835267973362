import {
  DELETE_COURSE,
  DELETE_COURSE_FAILURE,
  DELETE_COURSE_SUCCESS,
  GET_COURCES,
  GET_COURCES_FAILURE,
  GET_COURCES_SUCCESS,
  GET_SUB_COURSES,
  GET_SUB_COURSES_FAILURE,
  GET_SUB_COURSES_SUCCESS,
} from "../Actions/ActionTypes";

let initialState = {
  loading: false,
  courses: [],
  subcourses: [],
};

const CourseReducer = (state = initialState, actions) => {
  switch (actions.type) {
    // GET_SUB_COURSES
    case GET_SUB_COURSES:
      return { ...state, loading: true };

    case GET_SUB_COURSES_SUCCESS:
      return { ...state, subcourses: actions.payload, loading: false };

    case GET_SUB_COURSES_FAILURE:
      return { ...state, loading: false };

    // GET_COURCES
    case GET_COURCES:
      return { ...state, loading: true };

    case GET_COURCES_SUCCESS:
      return { ...state, courses: actions.payload, loading: false };

    case GET_COURCES_FAILURE:
      return { ...state, loading: false };

    // DELETE_COURSE
    case DELETE_COURSE:
      return { ...state, loading: true };

    case DELETE_COURSE_SUCCESS:
      return { ...state, loading: false };

    case DELETE_COURSE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default CourseReducer;
