import { toast } from "react-toastify";
import API from "./ActionTypes";
import {
  BASEURL,
  GET_ENROLLED,
  GET_ENROLLED_FAILURE,
  GET_ENROLLED_SUCCESS,
} from "./ActionTypes";

export const getEnrolled = () => async (dispatch) => {
  dispatch({
    type: GET_ENROLLED,
  });
  try {
    const res = await API.get(`${BASEURL}api/enrollment`);
    if (res.status === 200) {
      dispatch({
        type: GET_ENROLLED_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ENROLLED_FAILURE,
    });
    toast.error("Something went wrong !");
  }
};

export const getEnrolledByCourseId = (id) => async (dispatch) => {
  dispatch({
    type: GET_ENROLLED,
  });
  try {
    const res = await API.get(`${BASEURL}api/enrollment/course/${id}`);
    if (res.status === 200) {
      dispatch({
        type: GET_ENROLLED_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ENROLLED_FAILURE,
    });
    toast.error("Something went wrong !");
  }
};
