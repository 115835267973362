import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../Components/Heading";
import HOC from "../Layouts/HOC";
import {
  getAllSessions,
  getSessionsBySubcources,
} from "../Redux/Actions/SessionAction";
import { BASEURL } from "../Redux/Actions/ActionTypes";
import DataTable from "react-data-table-component";
import Dropdown from "../Components/Dropdown";
import { IoIosArrowBack } from "react-icons/io";
import ButtonLoader from "../Components/ButtonLoader";

const Session = () => {
  const [isSelected, setSelected] = useState(false);
  const [allsessions, setAllSessions] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subcourseId = new URLSearchParams(document.location.search).get(
    "subcourse-id"
  );

  const sessions = useSelector((state) => state.SessionReducer.sessions);
  const loading = useSelector((state) => state.SessionReducer.loading);

  const handleStatus = (e) => {
    setSelected(true);
    if (e === "NAN") {
      setAllSessions(sessions);
      setSelected(false);
    } else {
      const filteredData = sessions.filter((item) => item.is_complete === +e);
      setAllSessions(filteredData);
    }
  };

  //  table data
  const columns = [
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Name</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Time</div>
      ),
      selector: (row) => row.time,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Description
        </div>
      ),
      selector: (row) => row.description,
      sortable: true,
    },

    // {
    //   name: (
    //     <div className="uppercase tracking-wider font-bold text-sm">
    //       Thumbnail
    //     </div>
    //   ),
    //   selector: (row) => row.thumbnail,
    //   sortable: true,
    // },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">Status</div>
      ),
      selector: (row) => row.status,
      sortable: true,
    },
  ];

  const data = (isSelected ? allsessions : sessions).map((item) => {
    return {
      id: item._id,
      title: (
        <div className="capitalize">
          {!item.is_complete ? (
            <Link
              className="underline text-color"
              to={`/sessions-log/${item._id}`}
            >
              {item.name}
            </Link>
          ) : (
            <td className="px-4 py-3 ">{item.name}</td>
          )}
        </div>
      ),
      time: item.time,
      description: item.description,
      thumbnail: (
        <img
          src={`${BASEURL}${item.thumbnail}`}
          alt={item.name}
          className="h-20"
        />
      ),
      status: (
        <div className="text-center text-color uppercase flex gap-3">
          {item.is_complete ? "Completed" : "InComplete"}
        </div>
      ),
    };
  });

  useEffect(() => {
    if (subcourseId) {
      dispatch(getSessionsBySubcources(subcourseId));
    } else {
      dispatch(getAllSessions());
    }
  }, [dispatch, subcourseId]);

  return (
    <>
      <section>
        {/* Top */}
        <div className="flex border-b py-2 mb-4 items-center justify-between">
          <Heading title={`Sessions ${subcourseId ? "By Sub-Course" : ""}`} />

          <div className="flex items-center gap-2">
            <Dropdown
              options={[
                { value: 1, label: "Complete" },
                { value: 0, label: "InComplete" },
              ]}
              handleChange={(e) => handleStatus(e)}
            />
            {subcourseId && (
              <div
                className=" md:text-base text-xs sm:text-sm text-white cursor-pointer  w-8 h-8 flex justify-center items-center shadow rounded-full tracking-widest bg-indigo-500 hover:bg-indigo-600 "
                onClick={() => navigate(-1)}
              >
                <IoIosArrowBack />
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <ButtonLoader />
        ) : (
          <DataTable columns={columns} data={data} pagination />
        )}
      </section>
    </>
  );
};

export default HOC(Session);
