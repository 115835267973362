import { toast } from "react-toastify";
import API, {UPDATE_ORDER, UPDATE_ORDER_FAILURE, UPDATE_ORDER_SUCCESS } from "./ActionTypes";
import {
  BASEURL,
  GET_ORDER,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
 
} from "./ActionTypes";

// const url = process.env.REACT_APP_BASE_URL;

export const getInvoices = () => async (dispatch) => {
  dispatch({
    type: GET_ORDER,
  });
  try {
    const res = await API.get(`${BASEURL}api/payout`);
    if (res.status === 200) {
      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: res.data.data,
      });
    }
  } catch (error) {
    dispatch({ type: GET_ORDER_FAILURE });
    toast.error("Something went wrong");
  }
};



// Update Category
export const updateInvoice = (formData, token, id) => async (dispatch) => {
  dispatch({ type: UPDATE_ORDER });

  try {
    const payload = new FormData();
    payload.append("upi_transaction_id", formData.upi_transaction_id);
    payload.append("payDate", formData.payDate);
    payload.append("created_by", token._id);

    const res = await API.patch(`${BASEURL}api/payout/payouts/${id}`, payload);

    if (res.status === 200) {
      dispatch(getInvoices());
      toast.success("Invoice updated successfully");
    }

    dispatch({ type: UPDATE_ORDER_SUCCESS });
    
  } catch (e) {
    dispatch({ type: UPDATE_ORDER_FAILURE });
    toast.error("update payout Something went wrong!");
  }
};