import React from "react";

const Dropdown = ({ options, handleChange }) => {
  return (
    <div>
      <select
        onChange={(e) => handleChange(e.target.value)}
        className="w-full text-center  appearance-none  rounded border text-xs md:text-sm outline-none  cursor-pointer py-1 px-2 uppercase transition-colors duration-100 ease-in-out"
      >
        <option value="NAN" selected>
          Status
        </option>
        {options.map((opt) => {
          return <option value={opt.value}>{opt.label}</option>;
        })}
      </select>
    </div>
  );
};

export default Dropdown;
