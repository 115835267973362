import {
  GET_ENROLLED,
  GET_ENROLLED_FAILURE,
  GET_ENROLLED_SUCCESS,
} from "../Actions/ActionTypes";

let initialState = {
  loading: false,
  enrollments: [],
};

const EnrollmentReducer = (state = initialState, actions) => {
  switch (actions.type) {
    // GET_ENROLLED
    case GET_ENROLLED:
      return { ...state, loading: true };

    case GET_ENROLLED_SUCCESS:
      return { ...state, enrollments: actions.payload, loading: false };

    case GET_ENROLLED_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default EnrollmentReducer;
