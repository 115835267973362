import { toast } from "react-toastify";
import API from "./ActionTypes";
import {
  BASEURL,
  GET_CATEGORIES,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_SUCCESS,
  REMOVE_CATEGORIES,
  REMOVE_CATEGORIES_SUCCESS,
  REMOVE_CATEGORIES_FAILURE,
  ADD_CATEGORIES_REQUEST,
  ADD_CATEGORIES_REQUEST_SUCCESS,
  ADD_CATEGORIES_REQUEST_FAILURE,
  UPDATE_CATEGORIES_REQUEST,
  UPDATE_CATEGORIES_REQUEST_SUCCESS,
  UPDATE_CATEGORIES_REQUEST_FAILURE,
} from "./ActionTypes";

// Get Categories
export const getCategories = () => async (dispatch) => {
  dispatch({
    type: GET_CATEGORIES,
  });
  try {
    const res = await API.get(`${BASEURL}api/category`);
    dispatch({
      type: GET_CATEGORIES_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: GET_CATEGORIES_FAILURE,
    });
    toast.error("__getCategories : something went wrong !");
  }
};

// Add Category
export const addCategoryRequest =
  (formData, token, callback) => async (dispatch) => {
    dispatch({
      type: ADD_CATEGORIES_REQUEST,
    });

    try {
      const payload = new FormData();
      payload.append("name", formData.name);
      payload.append("image", formData.image);
      payload.append("created_by", token._id);
      console.log("pos2");
      const res = await API.post(`${BASEURL}api/category`, payload);
      if (res.status === 200) {
        dispatch(getCategories());
        dispatch({
          type: ADD_CATEGORIES_REQUEST_SUCCESS,
          payload: res.data.data,
        });
        toast.success(res.data.message);
        return callback();
      }
    } catch (e) {
      dispatch({
        type: ADD_CATEGORIES_REQUEST_FAILURE,
      });
      toast.error("__addCategoryRequest: something went wrong !");
    }
  };

// Update Category
export const updateCategoryRequest =
  (formData, token, id, callback) => async (dispatch) => {
    dispatch({
      type: UPDATE_CATEGORIES_REQUEST,
    });

    try {
      const payload = new FormData();
      payload.append("name", formData.name);
      payload.append("image", formData.image);
      payload.append("created_by", token._id);
      const res = await API.patch(`${BASEURL}api/category/${id}`, payload);
      if (res.status === 200) {
        dispatch(getCategories());
        toast.success("Category update Successfully");
      }
      dispatch({
        type: UPDATE_CATEGORIES_REQUEST_SUCCESS,
      });
      return callback();
    } catch (e) {
      dispatch({
        type: UPDATE_CATEGORIES_REQUEST_FAILURE,
      });
      toast.error("__updateCategoryRequest : something went wrong !");
    }
  };

// Delete Category
export const removeCategory = (action) => async (dispatch) => {
  dispatch({
    type: REMOVE_CATEGORIES,
  });
  try {
    const res = await API.delete(`${BASEURL}api/category/${action.deleteId}`);
    if (res.data.status === 200) {
      dispatch(getCategories());
      toast.success(res.data.message);
      dispatch({
        type: REMOVE_CATEGORIES_SUCCESS,
      });
    }
    action.callback();
  } catch (e) {
    dispatch({
      type: REMOVE_CATEGORIES_FAILURE,
    });
    toast.error("__removeCategory : Something went wrong !");
  }
};
