import React from "react";

const ButtonLoader = () => {
  return (
    <div className="py-5">
      <div className="flex items-center justify-center space-x-2">
        <div className="w-3 h-3 rounded-full animate-pulse bg-color"></div>
        <div className="w-3 h-3 rounded-full animate-pulse bg-color"></div>
        <div className="w-3 h-3 rounded-full animate-pulse bg-color"></div>
      </div>
    </div>
  );
};

export default ButtonLoader;
