import React from "react";
import { toast } from "react-toastify";
import { FiLogOut } from "react-icons/fi";
import { BiCategory } from "react-icons/bi";
import { TbMenu2, TbFileInvoice } from "react-icons/tb";
import { SiCoursera, SiGotomeeting } from "react-icons/si";
import { FaChalkboardTeacher, FaUsers, FaWpforms } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineSpaceDashboard, MdLanguage } from "react-icons/md";

const Links = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <MdOutlineSpaceDashboard className="  text-color font-bold" />,
  },
  {
    name: "Students",
    path: "/students",
    icon: <FaUsers className="  text-color font-bold" />,
  },

  {
    name: "Instructors",
    path: "/instructors",
    icon: <FaChalkboardTeacher className="  text-color font-bold" />,
  },
  {
    name: "Category",
    path: "/category",
    icon: <BiCategory className="  text-color font-bold" />,
  },
  {
    name: "Languages",
    path: "/languages",
    icon: <MdLanguage className="  text-color font-bold" />,
  },
  {
    name: "Courses",
    path: "/courses",
    icon: <SiCoursera className="  text-color font-bold" />,
  },
  {
    name: "Sessions",
    path: "/sessions",
    icon: <SiGotomeeting className="  text-color font-bold" />,
  },
  {
    name: "Enrollment",
    path: "/enrollments",
    icon: <FaWpforms className="  text-color font-bold" />,
  },
  {
    name: "Payout",
    path: "/invoices",
    icon: <TbFileInvoice className="  text-color font-bold" />,
  },
];

const Sidebar = ({ setResponsive, responsive }) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  return (
    <>
      <div className="relative shadow tracking-wider px-2  md:px-3 bg-white  h-full">
        {/* Toggle */}
        <div className="flex items-center py-3 px-2  justify-between">
          <button
            onClick={() => setResponsive(!responsive)}
            className={`${
              !responsive && "pl-3"
            } pl-3  md:inline-block hidden md:pl-0 md:mt-5 `}
          >
            <TbMenu2 className="text-xl sm:text-2xl text-color" />
          </button>
        </div>

        {/* NavLinks*/}
        <div className="  flex flex-col gap-4 py-3 md:py-4">
          {Links.map((link, i) => {
            return (
              <Link
                title={link.name}
                className={`${
                  pathname === link.path && "bg-slate-200"
                } py-1.5 px-2 hover:bg-slate-200 rounded`}
                key={i}
                to={link.path}
              >
                <div
                  // title={link.name}
                  onClick={() => {
                    responsive === true
                      ? setResponsive(true)
                      : setResponsive(false);
                  }}
                  className={`flex text-sm items-center font-medium justify-center md:justify-start md:gap-2 cursor-pointer text-gray-900`}
                >
                  <span className="sm:text-2xl text-xl">{link.icon}</span>
                  <span
                    className={`${
                      responsive ? "hidden" : "md:inline-flex hidden"
                    }`}
                  >
                    {link.name}
                  </span>
                </div>
              </Link>
            );
          })}
        </div>

        {/* Log Out  */}
        <div className="bg-white">
          <span className="absolute bg-white justify-center gap-2.5 md:justify-start flex items-center   px-5 w-full bottom-0 left-0">
            <span
              title="Log Out"
              onClick={() => {
                localStorage.clear();
                toast.success("LogOut Success!");
                navigate("/");
              }}
              className="tracking-widest  text-sm justify-center md:justify-start  gap-2 flex items-center border-t py-4   text-red-600  cursor-pointer  w-full "
            >
              <FiLogOut className="text-xl" />
              <span className={`${responsive ? "hidden" : "inline-flex"}`}>
                Log Out
              </span>
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
