import HOC from "../Layouts/HOC";
import React, { useEffect } from "react";
import Heading from "../Components/Heading";
import { IoIosArrowBack } from "react-icons/io";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSubCources } from "../Redux/Actions/CourceAction";
import ButtonLoader from "../Components/ButtonLoader";

const SubCourses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const course_id = new URLSearchParams(document.location.search).get(
    "course_id"
  );
  const loading = useSelector((state) => state.CourseReducer.loading);
  const course = useSelector((state) => state.CourseReducer.subcourses);

  //  table data
  const columns = [
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Name</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Price</div>
      ),
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">Image</div>
      ),
      selector: (row) => row.image,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          Description
        </div>
      ),
      selector: (row) => row.description,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          Actions
        </div>
      ),
      selector: (row) => row.actions,
    },
  ];

  const data = course.map((item, index) => {
    return {
      id: item._id,
      title: <div className="capitalize">{item.name}</div>,
      price: <div className="capitalize">Rs. {item.price}</div>,

      image: (
        <img
          src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
          alt={item.name}
          className="w-32 my-3 object-cover object-center"
        />
      ),
      description: item.description,
      actions: (
        <div className="font-bold flex gap-3 text-xl">
          <Link
            to={`/sessions/?subcourse-id=${item._id}`}
            className="uppercase underline text-xs cursor-pointer font-medium text-color"
          >
            View Sessions
          </Link>
        </div>
      ),
    };
  });

  useEffect(() => {
    if (course_id) {
      dispatch(getSubCources(course_id));
    }
  }, [dispatch, course_id]);

  return (
    <>
      <div className="flex border-b py-2 mb-4 items-center justify-between">
        <Heading title={`Sub-Courses`} />
        {course_id && (
          <div
            className=" md:text-base text-xs sm:text-sm text-white cursor-pointer  w-8 h-8 flex justify-center items-center shadow rounded-full tracking-widest bg-indigo-500 hover:bg-indigo-600 "
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack />
          </div>
        )}
      </div>

      {loading ? (
        <ButtonLoader />
      ) : (
        <DataTable columns={columns} data={data} pagination />
      )}
    </>
  );
};

export default HOC(SubCourses);
