import Category from "../Pages/Category";
import Courses from "../Pages/Courses";
import Enrollment from "../Pages/Enrollment";
import ForgotPass from "../Pages/ForgotPass";
import Home from "../Pages/Home";
import Instructer from "../Pages/Instructer";
import InstructorProfile from "../Pages/InstructorProfile";
import Invoice from "../Pages/Invoice";
import Languages from "../Pages/Languages";
import Login from "../Pages/Login";
import Profile from "../Pages/Profile";
import Sessions from "../Pages/Sessions";
import Signup from "../Pages/Signup";
import Students from "../Pages/Students";
import SubCourses from "../Pages/SubCourses";

const AllRoutes = [
  {
    name: "Home",
    path: "/dashboard",
    component: <Home />,
    private: true,
  },
  {
    name: "Profile",
    path: "/profile",
    component: <Profile />,
    private: true,
  },

  {
    name: "Students",
    path: "/students",
    component: <Students />,
    private: true,
  },

  {
    name: "Instructor",
    path: "/instructors",
    component: <Instructer />,
    private: true,
  },

  {
    name: "InstructorProfile",
    path: "/instructor_profile",
    component: <InstructorProfile />,
    private: true,
  },

  {
    name: "Category",
    path: "/category",
    component: <Category />,
    private: true,
  },

  {
    name: "Languages",
    path: "/languages",
    component: <Languages />,
    private: true,
  },

  {
    name: "Courses",
    path: "/courses",
    component: <Courses />,
    private: true,
  },

  {
    name: "SubCourses",
    path: "/sub_courses",
    component: <SubCourses />,
    private: true,
  },

  {
    name: "Sessions",
    path: "/sessions",
    component: <Sessions />,
    private: true,
  },

  {
    name: "Enrollments",
    path: "/enrollments",
    component: <Enrollment />,
    private: true,
  },

  {
    name: "Invoice",
    path: "/invoices",
    component: <Invoice />,
    private: true,
  },

  {
    name: "Signup",
    path: "/sign-up",
    component: <Signup />,
    private: false,
  },
  {
    name: "Login",
    path: "/",
    component: <Login />,
    private: false,
  },
  {
    name: "Forgot",
    path: "/forgot",
    component: <ForgotPass />,
    private: false,
  },
];

export default AllRoutes;
