import React, { useEffect, useState } from "react";
import HOC from "../Layouts/HOC";
import { BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Components/Modal";
import {
  AllStudents,
  DeleteStudent,
  BlockStudent,
} from "../Redux/Actions/StudentsAction";
import Heading from "../Components/Heading";

import DataTable from "react-data-table-component";
import Dropdown from "../Components/Dropdown";

import ButtonLoader from "../Components/ButtonLoader";

const pageSize = 100;

const Students = () => {
  const [isSelected, setSelected] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [isDelete, setDelete] = useState(false);
  const [allStudents, setAllStudents] = useState();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.StudentReducer.loading);
  const usersDetail = useSelector((state) => state.StudentReducer.students);

  //  table data
  const columns = [
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Name</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Email</div>
      ),
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Phone</div>
      ),
      selector: (row) => row.phone,
      sortable: true,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Active
        </div>
      ),
      selector: (row) => row.active,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Verified
        </div>
      ),
      selector: (row) => row.verified,
    },
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Priv User
        </div>
      ),
      selector: (row) => row.priv_user,
    },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          {" "}
          Actions
        </div>
      ),
      selector: (row) => row.actions,
    },
  ];

  const data = (isSelected ? allStudents : usersDetail?.data)?.map((item) => {
    return {
      id: item._id,
      title: <div className="capitalize">{item.name}</div>,
      email: item.email,
      phone: item.phone,
      active: (
        <label
          for={item._id}
          className="inline-flex items-center space-x-4 cursor-pointer text-gray-100"
        >
          <span className="relative">
            <input
              id={item._id}
              type="checkbox"
              checked={item.active !== 0}
              className="hidden peer"
              onChange={() => {
                {
                  dispatch(
                    BlockStudent(item._id, "active", {
                      active: item.active === 0 ? 1 : 0,
                    })
                  );
                }
              }}
            />
            <div className="w-10 h-6 rounded-full shadow-inner bg-gray-400 peer-checked:bg-sky-300"></div>
            <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-white"></div>
          </span>
        </label>
      ),
      verified: (
        <label
          for={`${item._id}${item.name}`}
          className="inline-flex items-center space-x-4 cursor-pointer text-gray-100"
        >
          <span className="relative">
            <input
              id={`${item._id}${item.name}`}
              type="checkbox"
              checked={item.is_verified !== 0}
              className="hidden peer"
              onChange={() => {
                {
                  dispatch(
                    BlockStudent(item._id, "is_verified", {
                      is_verified: item.is_verified === 0 ? 1 : 0,
                    })
                  );
                }
              }}
            />
            <div className="w-10 h-6 rounded-full shadow-inner bg-gray-400 peer-checked:bg-sky-300"></div>
            <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-white"></div>
          </span>
        </label>
      ),
      priv_user: (
        <label
          for={item.email}
          className="inline-flex items-center space-x-4 cursor-pointer text-gray-100"
        >
          <span className="relative">
            <input
              id={item.email}
              type="checkbox"
              checked={item.is_priv_user !== 0}
              className="hidden peer"
              onChange={() => {
                {
                  dispatch(
                    BlockStudent(item._id, "priv_user", {
                      is_priv_user: item.is_priv_user === 0 ? 1 : 0,
                    })
                  );
                }
              }}
            />
            <div className="w-10 h-6 rounded-full shadow-inner bg-gray-400 peer-checked:bg-sky-300"></div>
            <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-white"></div>
          </span>
        </label>
      ),      
      actions: (
        <BiTrash
          onClick={() => handleConfirmDelete(item._id)}
          title="Delete"
          className="cursor-pointer font-bold gap-3 text-xl md:text-2xl text-red-600"
        />
      ),
    };
  });

  // delete student
  const handleDeleteUser = () => {
    dispatch(DeleteStudent({ deleteId, callback: () => setDelete(false) }));
  };
  const handleStatus = (e) => {
    setSelected(true);
    if (e === "NAN") {
      setAllStudents(usersDetail?.data);
      setSelected(false);
    } else {
      const filteredData = usersDetail?.data.filter(
        (item) => item.active === +e
      );
      setAllStudents(filteredData);
    }
  };

  const handleConfirmDelete = (id) => {
    setDeleteId(id);
    setDelete(true);
  };

  // useEffects
  useEffect(() => {
    dispatch(AllStudents({ page: pageCount, limit: pageSize }));
  }, [dispatch, pageCount]);

  return (
    <>
      {/* Top */}
      <div className="flex border-b py-2 mb-4 items-center justify-between">
        <Heading title={"Students"} />
        <Dropdown
          options={[
            { value: 1, label: "Active" },
            { value: 0, label: "InActive" },
          ]}
          handleChange={(e) => handleStatus(e)}
        />
      </div>

      {/* {loading ? (
        <ButtonLoader />
      ) : ( */}
      <DataTable columns={columns} data={data} pagination />
      {/* )} */}

      {/* To delete */}
      {isDelete && (
        <Modal
          handleDeleteInstructors={handleDeleteUser}
          setDelete={setDelete}
          loading={loading}
        />
      )}
    </>
  );
};

export default HOC(Students);
