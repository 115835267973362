import axios from "axios";

// BASEURL
export const BASEURL = process.env.REACT_APP_BASE_URL;
export const ADMIN_BASEURL = `${process.env.REACT_APP_BASE_URL}api/admin/`;
export const Token = localStorage.getItem("access_token");

// REGISTER
export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

// LOGIN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

// ---------- Students --------------- //

export const GET_STUDENT = "GET_STUDENT";
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS";
export const GET_STUDENT_FAILURE = "GET_STUDENT_FAILURE";

export const DELETE_STUDENT = "DELETE_STUDENT";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FAILURE = "DELETE_STUDENT_FAILURE";

export const BLOCK_STUDENT = "BLOCK_STUDENT";
export const BLOCK_STUDENT_SUCCESS = "BLOCK_STUDENT_SUCCESS";
export const BLOCK_STUDENT_FAILURE = "BLOCK_STUDENT_FAILURE";

// ---------- Instructor ----------- //

export const GET_INSTRUCTER = "GET_INSTRUCTER";
export const GET_INSTRUCTER_SUCCESS = "GET_INSTRUCTER_SUCCESS";
export const GET_INSTRUCTER_FAILURE = "GET_INSTRUCTER_FAILURE";

export const DELETE_INSTRUCTER = "DELETE_INSTRUCTER";
export const DELETE_INSTRUCTER_SUCCESS = "DELETE_INSTRUCTER_SUCCESS";
export const DELETE_INSTRUCTER_FAILURE = "DELETE_INSTRUCTER_FAILURE";

export const BLOCK_INSTRUCTER = "BLOCK_INSTRUCTER";
export const BLOCK_INSTRUCTER_SUCCESS = "BLOCK_INSTRUCTER_SUCCESS";
export const BLOCK_INSTRUCTER_FAILURE = "BLOCK_INSTRUCTER_FAILURE";

// ------------ Categories ----------- //

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";

export const ADD_CATEGORIES_REQUEST = "ADD_CATEGORIES_REQUEST";
export const ADD_CATEGORIES_REQUEST_SUCCESS = "ADD_CATEGORIES_REQUEST_SUCCESS";
export const ADD_CATEGORIES_REQUEST_FAILURE = "ADD_CATEGORIES_REQUEST_FAILURE";

export const UPDATE_CATEGORIES_REQUEST = "UPDATE_CATEGORIES_REQUEST";
export const UPDATE_CATEGORIES_REQUEST_SUCCESS =
  "UPDATE_CATEGORIES_REQUEST_SUCCESS";
export const UPDATE_CATEGORIES_REQUEST_FAILURE =
  "UPDATE_CATEGORIES_REQUEST_FAILURE";

export const REMOVE_CATEGORIES = "REMOVE_CATEGORIES";
export const REMOVE_CATEGORIES_SUCCESS = "REMOVE_CATEGORIES_SUCCESS";
export const REMOVE_CATEGORIES_FAILURE = "REMOVE_CATEGORIES_FAILURE";

// ------------ Lanuages ----------- //

export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_FAILURE = "GET_LANGUAGE_FAILURE";

export const ADD_LANGUAGE_REQUEST = "ADD_LANGUAGE_REQUEST";
export const ADD_LANGUAGE_REQUEST_SUCCESS = "ADD_LANGUAGE_REQUEST_SUCCESS";
export const ADD_LANGUAGE_REQUEST_FAILURE = "ADD_LANGUAGE_REQUEST_FAILURE";

export const UPDATE_LANGUAGE_REQUEST = "UPDATE_LANGUAGE_REQUEST";
export const UPDATE_LANGUAGE_REQUEST_SUCCESS =
  "UPDATE_LANGUAGE_REQUEST_SUCCESS";
export const UPDATE_LANGUAGE_REQUEST_FAILURE =
  "UPDATE_LANGUAGE_REQUEST_FAILURE";

export const REMOVE_LANGUAGE = "REMOVE_LANGUAGE";
export const REMOVE_LANGUAGE_SUCCESS = "REMOVE_LANGUAGE_SUCCESS";
export const REMOVE_LANGUAGE_FAILURE = "REMOVE_LANGUAGE_FAILURE";

//  -------- Courses & Subcourses ----------- //

export const GET_COURCES = "GET_COURCES";
export const GET_COURCES_SUCCESS = "GET_COURCES_SUCCESS";
export const GET_COURCES_FAILURE = "GET_COURCES_FAILURE";

export const DELETE_COURSE = "DELETE_COURSE";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";

export const GET_SUB_COURSES = "GET_SUB_COURSES";
export const GET_SUB_COURSES_SUCCESS = "GET_SUB_COURSES_SUCCESS";
export const GET_SUB_COURSES_FAILURE = "GET_SUB_COURSES_FAILURE";

//  --------- Sessions ---------- //

export const GET_SESSIONS = "GET_SESSIONS";
export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS";
export const GET_SESSIONS_FAILURE = "GET_SESSIONS_FAILURE";

export const DELETE_SESSIONS = "DELETE_SESSIONS";
export const DELETE_SESSIONS_SUCCESS = "DELETE_SESSIONS_SUCCESS";
export const DELETE_SESSIONS_FAILURE = "DELETE_SESSIONS_FAILURE";

// ---------- Enrolled ---------- //

export const GET_ENROLLED = "GET_ENROLLED";
export const GET_ENROLLED_SUCCESS = "GET_ENROLLED_SUCCESS";
export const GET_ENROLLED_FAILURE = "GET_ENROLLED_FAILURE";

// ------------ Orders ---------- //

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const UPDATE_ORDER="UPDATE_ORDER";
export const UPDATE_ORDER_SUCCESS="UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE="UPDATE_ORDER_FAILURE";

// --------------------------------------- API Intercepture --------------------------- //

const API = axios.create();

API.interceptors.request.use(
  (config) => {
    if (Token) {
      config.headers.token = Token;
      config.headers.adminToken = Token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location("/login");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location("/sign-up");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export default API;
