import React, { useEffect, useState } from "react";
import Button from "../Components/Button";
import HOC from "../Layouts/HOC";
import { BiTrash, BiEdit } from "react-icons/bi";
import { getCategories, removeCategory } from "../Redux/Actions/CategoryAction";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Components/Modal";
import Heading from "../Components/Heading";

import DataTable from "react-data-table-component";
import AddCategory from "../Components/Modals/AddCategory";
import { Link } from "react-router-dom";
import ButtonLoader from "../Components/ButtonLoader";

const Category = () => {
  const [edit, setEdit] = useState();
  const [popup, setPopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isDelete, setDelete] = useState(false);

  const dispatch = useDispatch();

  const categories = useSelector((state) => state.CategoryReducer.categories);
  const loading = useSelector((state) => state.CategoryReducer.loading);

  //  table data
  const columns = [
    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm"> Name</div>
      ),
      selector: (row) => row.title,
      sortable: true,
    },

    // {
    //   name: (
    //     <div className="uppercase tracking-wider font-bold text-sm">Image</div>
    //   ),
    //   selector: (row) => row.image,
    // },

    {
      name: (
        <div className="uppercase tracking-wider font-bold text-sm">
          Actions
        </div>
      ),
      selector: (row) => row.actions,
    },
  ];

  const data = categories?.map((item, index) => {
    return {
      id: item._id,
      title: (
        <Link
          to={`/courses?category_id=${item._id}`}
          className="capitalize underline text-color"
        >
          {item.name}
        </Link>
      ),
      image: (
        <img
          src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
          alt={item.name}
          className="w-32 my-3 object-cover object-center"
        />
      ),
      actions: (
        <div className="font-bold flex gap-3 text-xl">
          <BiTrash
            onClick={() => handleConfirmDelete(item._id)}
            className="cursor-pointer text-red-600"
          />
          <BiEdit
            onClick={() => {
              setPopup(true);
              setEdit(item);
            }}
            className="cursor-pointer text-color"
          />
        </div>
      ),
    };
  });

  // Close Popup
  const close = (event) => {
    setPopup(event);
  };

  const handleDeleteCategory = () => {
    dispatch(removeCategory({ deleteId, callback: () => setDelete(false) }));
  };

  const handleConfirmDelete = (id) => {
    setDeleteId(id);
    setDelete(true);
  };

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <>
      <section>
        <div className="flex border-b py-2 mb-4 items-center justify-between">
          <Heading title={"Categories"} />
          <span
            onClick={() => {
              setPopup(true);
              setEdit();
            }}
          >
            <Button button={"Add New"} />
          </span>
        </div>
        {loading ? (
          <ButtonLoader />
        ) : (
          <DataTable columns={columns} data={data} pagination sorting />
        )}
      </section>

      {/* To Add Category */}
      <section
        className={`absolute top-0 ${
          popup ? "flex" : "hidden"
        }  justify-center z-50 items-center left-0 h-screen w-full bg-[rgb(0,0,0,0.7)]`}
      >
        <AddCategory close={close} edit={edit} loading={loading} />
      </section>

      {/* To Delete */}
      {isDelete && (
        <Modal
          handleDeleteInstructors={handleDeleteCategory}
          setDelete={setDelete}
          loading={loading}
        />
      )}
    </>
  );
};

export default HOC(Category);
