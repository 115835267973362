import {
  BLOCK_STUDENT,
  BLOCK_STUDENT_FAILURE,
  BLOCK_STUDENT_SUCCESS,
  DELETE_STUDENT,
  DELETE_STUDENT_FAILURE,
  DELETE_STUDENT_SUCCESS,
  GET_STUDENT,
  GET_STUDENT_FAILURE,
  GET_STUDENT_SUCCESS
} from "../Actions/ActionTypes";

let initialState = {
  loading: false,
  students: []
};

const StudentReducer = (state = initialState, actions) => {
  switch (actions.type) {
    // BLOCK_STUDENT
    case BLOCK_STUDENT:
      return { ...state, loading: true };

    case BLOCK_STUDENT_SUCCESS:
      return { ...state, loading: false };

    case BLOCK_STUDENT_FAILURE:
      return { ...state, loading: false };

    // GET_STUDENT
    case GET_STUDENT:
      return { ...state, loading: true };

    case GET_STUDENT_SUCCESS:
      return { ...state, students: actions.payload, loading: false };

    case GET_STUDENT_FAILURE:
      return { ...state, loading: false };

    // DELETE_STUDENT
    case DELETE_STUDENT:
      return { ...state, loading: true };

    case DELETE_STUDENT_SUCCESS:
      return { ...state, loading: false };

    case DELETE_STUDENT_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default StudentReducer;
